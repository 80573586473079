.ann_title {
  margin-top: -1rem;
  margin-bottom: 0.5rem;
  letter-spacing: 0.5rem;
  text-transform: uppercase;
  font-size: clamp(1.25rem, 3vw, 2.5rem);
}

.announcements {
  /* background-color: #000000aa; */
}

.announcement {
  background-color: rgb(0 0 0 / 0.6);
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  outline: 2px rgb(255 255 255 / 0.5) solid;
  outline-offset: -0.5rem;
}

.announcement + .announcement {
  margin-top: 1rem;
}

.announcement h3 {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto 0.5rem;
  border-bottom: 1px solid white;
  font-size: clamp(0.875rem, 2vw, 1.5rem);
}

.announcement p {
  font-size: clamp(1rem, 2.5vw, 1.75rem);
}

.announcement .links {
  /* background-color: white; */
  margin-top: 0.5rem;
  padding: 0.5rem;
}

.announcement .links a {
  padding: 0.25rem 0.75rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  background-color: rgb(255 255 255 / 0.15);
  color: #0078e3;
}

.announcement .links a + a {
  margin-left: 0.5rem;
}

@media (max-width: 600px) {
  .ann_title {
    margin-top: initial;
    letter-spacing: 0.25rem;
  }
}
