.wrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}

.wrapper > h3 {
  font-size: 2rem;
  flex-basis: 100%;
}

.wrapper > i {
  font-size: 2rem;
  cursor: pointer;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-7-23 3:28:16
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.alert_circle,
.alert_circle_2 {
  position: absolute;
  left: 34px;
  z-index: 5;
  font-size: 16px;
  color: orange;
  /* color: red; */
  -webkit-animation: scale-up-center 0.5s ease-in-out infinite alternate-reverse
    both;
  animation: scale-up-center 0.5s ease-in-out infinite alternate-reverse both;
}

.alert_circle {
  margin-top: -5px;
}

.alert_circle_2 {
  margin-top: 44px;
}

.fa-bell,
.fa-exclamation {
  position: absolute;
  left: 0;
  background-color: #0078e3;
  font-size: 1.4rem;
  padding: 10px;
  padding-right: 12px;
  cursor: pointer;
  -webkit-border-top-right-radius: 10px;
  -webkit-border-bottom-right-radius: 10px;
  -moz-border-radius-topright: 10px;
  -moz-border-radius-bottomright: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 3;
}

.fa-exclamation {
  margin-top: 50px;
  padding: 10px 17px;
}

.fa-times {
  margin-right: 1rem;
}

div::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
div::-webkit-scrollbar-thumb {
  background: #aaa;
}

/* Handle on hover */
div::-webkit-scrollbar-thumb:hover {
  background: #888;
}

/* .banner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.banner img {
  max-width: 100%;
} */

.banner img {
  object-fit: contain;
  width: 100%;
}

.ann_link {
  width: fit-content;
  width: -moz-fit-content;
  margin: 1rem auto;
  margin-top: 0.5rem;
  background-color: #0078e3;
  padding: 0.5rem 10vw;
  cursor: pointer;
  transition: transform 0.3s;
  color: white;
  border-radius: 0.5rem;
}

.ann_link:hover {
  transform: scale(1.05);
}

.ann_link a {
  color: inherit;
  font-size: clamp(1rem, 2vw, 2rem);
  text-decoration: none;
  font-weight: 600;
}

@media (max-width: 600px) {
  .ann_link:hover {
    transform: none;
  }
}
