@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
.palettes {
  color: #18293b;
  color: #0078e3;
  color: #4adede;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', 'Tajawal', sans-serif;
}

html {
  height: 100%;
}

body {
  --stripe: #18293b;
  --bg: #142333;
  background: linear-gradient(135deg, var(--bg) 25%, transparent 25%) -50px 0,
    linear-gradient(225deg, var(--bg) 25%, transparent 25%) -50px 0,
    linear-gradient(315deg, var(--bg) 25%, transparent 25%),
    linear-gradient(45deg, var(--bg) 25%, transparent 25%);
  background-size: 100px 100px;
  background-color: var(--stripe);
  height: 100%;
}

.content {
  min-height: calc(100vh - 200px);
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 30px;
  text-align: center;
  color: white;
}

.detected {
  background: rgb(220 0 0 / 0.4);
  text-align: center;
  padding: 1rem;
  color: white;
  margin-bottom: -1rem;
  /* font-size: 1rem; */
}

.arb,
.arb p,
.arb a {
  font-family: 'Tajawal', sans-serif;
}

@media (max-width: 600px) {
  .content {
    padding: 15px;
  }
  .detected {
    margin-bottom: 0;
  }
}
