.title {
  margin-top: -10px;
  font-size: clamp(2rem, 4vw, 3rem);
}

.doctor {
  margin-top: 10px;
  font-weight: 300;
  font-size: clamp(1.2rem, 3vw, 1.8rem);
  cursor: pointer;
}

.teams {
  margin-top: 10px;
  font-weight: 300;
  font-size: clamp(1rem, 2vw, 1.2rem);
}

.empty {
  padding: 20px;
  margin-top: 50px;
  font-size: clamp(1.4rem, 3vw, 2rem);
  border: 6px solid #0078e3;
}

@media screen and (max-width: 600px) {
  .title {
    margin-top: 0;
  }
}
