:root {
  --cs: #ea2027;
  --sc: #78d237;
  --is: #ffd246;
  --sys: #2d73f5;
}

.course_title {
  position: relative;
  background: whitesmoke;
  color: #333;
  font-size: clamp(1rem, 2vw, 2rem);
  font-weight: 700;
  text-align: left;
  padding: 15px;
  padding-left: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.course_title:hover {
  background: #ddd;
}

.cs,
.sc,
.is,
.sys {
  position: absolute;
  width: 30px;
  height: 100%;
  bottom: 0;
  mix-blend-mode: multiply;
}

.cs {
  right: 100px;
  background-color: var(--cs);
}

.sc {
  right: 70px;
  background-color: var(--sc);
}

.is {
  right: 40px;
  background-color: var(--is);
}

.sys {
  right: 10px;
  background-color: var(--sys);
}

@media (max-width: 800px) {
  .course_title {
    padding: 10px;
    margin-bottom: 10px;
  }
  .warp {
    padding-right: 100px;
  }
  .cs,
  .sc,
  .is,
  .sys {
    width: 20px;
  }
  .cs {
    right: 65px;
  }
  .sc {
    right: 45px;
  }
  .is {
    right: 25px;
  }
  .sys {
    right: 5px;
  }
}
